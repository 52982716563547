<template>
  <div>
    <el-table v-loading="loading" :data="list" border style="width: 100%;">
      <el-table-column prop="id" label="编号"></el-table-column>
      <el-table-column prop="fee" label="提现金额"></el-table-column>
      <el-table-column prop="created_at" label="申请时间" show-overflow-tooltip>
        <template #default="s">
          <div>{{s.row.created_at | date}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="提现状态" width="80">
        <template #default="s">
          <el-tag v-if="s.row.status === 2" type="success" effect="dark" size="small">已打款</el-tag>
          <el-tag v-else type="info" effect="dark" size="small">待打款</el-tag>
        </template>
      </el-table-column>      <el-table-column prop="pay_time" label="打款时间" show-overflow-tooltip>
        <template #default="s">
          <div>{{s.row.pay_time | date}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="member.id" label="申请账户id"  show-overflow-tooltip></el-table-column>
      <el-table-column prop="member.nickname" label="昵称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="member.avatar" label="头像">
        <template #default="s">
          <div v-if="s.row.member">
            <img :src="s.row.member.avatar | tomedia" style="height: 3rem;width: 3rem;border-radius: .5rem" alt="">
          </div>
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "info",
  data(){
    return{
      loading:false,
      page:1,
      total:0,
      size:0,
      list:[]
    }
  },
  mounted() {
    this.loadList();
  },
  methods:{
    pageChange(e) {
      this.page = e;
      this.loadList();
    },
    loadList(){
      this.loading = true;
      this.$api.plugin.agent.UCashList({
        page:this.page
      }).then(res=>{
        this.loading =false;
        this.list = res.list;
        this.total = res.total;
        this.size = res.size;
      })
    },
  }
}
</script>

<style scoped>
  .y-box{
    width: 100%;
    margin: 1rem 0;
  }
  .y-box>div{
    width: 33%;
    border-left: 1px solid #ededed;
  }
  .y-box>div:first-child{
    border-left: unset;
  }
</style>